import React from "react";

import part1 from "../components/image/16.png";
import part2 from "../components/image/18.png";
import Parallax from "./Parallax";
import Carousel from "./Carousel";
//
import homeD from "./video/Desktop/home/home.mp4";

const Index = () => {
  return (
    <>
      <Parallax />
      <section className="index1 fluid">
        <div className="one">
          <h1>01</h1>
        </div>
        <div className="two">
          <h1>02</h1>
        </div>
        <div className="three">
          <h1>03</h1>
        </div>
      </section>

      <Carousel />

      <section className="index2 fluid">
        <video loop autoPlay muted>
          <source src={homeD} type="video/mp4" />
        </video>
      </section>

      <section className="index3 fluidAuto grid1">
        <div className="part1">
          <img src={part1} alt="img" style={{ mixBlendMode: "exclusion" }} />
        </div>
        <div className="part2">
          <img src={part2} alt="img" style={{ mixBlendMode: "exclusion", zIndex : "-1" }} />
          <div className="content">
            <h1>energy</h1>
            <h1>drink</h1>

            <p>
              The global market of energy drinks is growing rapidly because the
              modern world needs something energetic for their highly hectic and
              fast paced life. So FashionTV has launched their brand new and
              refreshing FTV energy drinks just for you, with a sip that is
              going to be the best part of your long and hectic days by giving
              you enough energy to rock the day.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
