import React from "react";
import Parallax from "./Parallax";
import RedBlue from "./RedBlue";
//
import fashion1 from "./video/Desktop/fashiontv/fashion1.mp4";

const FashionTv = () => {
  return (
    <>
      <Parallax />

      <section className="adam1 fluid">
        <video loop autoPlay muted>
          <source src={fashion1} type="video/mp4" />
        </video>
        <div className="content">
          <h1>fashiontv</h1>
          <h2>world's largest fashion media</h2>
        </div>
      </section>

      <section className="fashion2 fluid" style={{ position: "relative" }}>
        <RedBlue />
        <div className="container1">
          <h1>introduction</h1>
          <p>
            Welcome to a new realm of FASHION AND LIFESTYLE! <br /> Welcome to a
            whole different nebula where fashion, glamour and an opulent way of
            life embellishes the world. FashionTV has nurtured fashion into a
            global phenomenon. A space that inspires and adorns fashion
            enthusiasts with the power of media.
          </p>
        </div>
      </section>

      <section className="fashion3 fluid" style={{ position: "relative" }}>
        <RedBlue />
        <div className="container1">
          <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
            key facts
          </h1>
          <ul>
            <li>
              <span>
                FashionTV is the first fashion channel in the world with
              </span>{" "}
              250 million viewers in 193+ countries.
            </li>
            <li>
              <span>
                It broadcasts the latest news and information about the fashion,
                luxury, and lifestyle world to over{" "}
              </span>{" "}
              100 million households <span>and over</span> 10 million{" "}
              <span>public places across five continents.</span>
            </li>
            <li>
              <span>
                FashionTV is the fourth most distributed channel in the world
                with
              </span>{" "}
              250 cable satellites, 500 million households, 7 million in public
              places, and 10 million public TV sets in public places.
            </li>
          </ul>
        </div>
      </section>

      <section className="fashion4 fluid">
        <div className="container1">
          <h1 style={{ textAlign: "center", marginBottom: "10px" }}>
            social media
          </h1>
          <p>
            FTV launched into social media with a gallon of multi-genre content.
            On Facebook, Fashion TV has a total fan base of over
            <span> 5 million users.</span> Our Facebook page has more than four
            million+ likes and gets <span>12 million+</span> views per week.
            Fashion TV YouTube channel gets <span> 65 million+</span> views. On
            <span> Instagram</span>, Fashion TV has
            <span> 300k+ followers</span> and also has
            <span> 125k+ organic reach</span> per month.
          </p>
        </div>
      </section>
    </>
  );
};

export default FashionTv;
