import React from "react";
import Parallax from "./Parallax";

const Terms = () => {
  return (
    <>
      <Parallax />
      <div className="fluidAuto" style={{ padding: "100px 10px 50px" }}>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Careers</h1>
        <h3>Introduction</h3>
        <p>
          The terms and conditions written in this website shall control your
          use of our website. Those terms may be applied genuinely and affect
          your use of this website. By way of using this website, you agreed to
          accept all the terms and conditions written in right here. You need to
          no longer use this website in case you disagree with any of these
          internet website terms and conditions.
        </p>{" "}
        <br />
        <br />
        <h3>Intellectual Property Rights.</h3>
        <p>
          Other than your personal content material, this internet website
          contains FashionTV India LTD and/or its licensors very personal all of
          the intellectual property rights below these terms and conditions.
          You're granted a confined license just for functions of viewing the
          substances contained on this internet site.
        </p>{" "}
        <br />
        <br />
        <h3>Restrictions</h3>
        <ul>
          <li>Publishing any internet site material in another media.</li>
          <li>
            Promoting, sublicensing and/or in any other case commercializing any
            website fabric.
          </li>
          <li>Publicly appearing and/or showing any website materials.</li>
          <li>
            Using this internet site in any way that may be destructive to this
            internet site.
          </li>
          <li>
            The use of this website in any manner that affects person get
            admission to this website.
          </li>
          <li>
            The usage of this website contrary to applicable legal guidelines
            and policies, or in any manner may additionally cause damage to the
            internet site, or to any individual or enterprise entity.
          </li>
          <li>
            Accomplishing any facts mining, data harvesting, data extracting or
            any other activity in terms of this internet site.
          </li>
          <li>
            using the internet site to have interaction in any marketing or
            advertising.
          </li>
        </ul>
        <p>
          Some regions of this website are confined from being accessed by you
          and FashionTV India LTD can also similarly limit access by using you
          to any regions of this internet site, at any time, in absolute
          discretion. Any identification and password you may have for this
          internet site are confidential and you must keep it confidential.
        </p>{" "}
        <br />
        <br />
        <h3>Your Content.</h3>
        <p>
          “Your content” can be any audio, video, text content, images or
          different material you select to display on this website. You grant a
          non-exclusive, international irrevocable, sub-licensable license to
          apply, reproduce, adapt, publish, translate and distribute it in any
          and all media by means of displaying your content material on
          FashionTV India LTD.
          <br />
          Your content material has to be generated by your own and should not
          be invading any third party's rights. FashionTV India LTD reserves the
          proper right to remove any of your content from their website at any
          time without any notice.
        </p>{" "}
        <br />
        <br />
        <h3>No Warranties.</h3>
        <p>
          FashionTV India LTD provides no representations or warranties, of any
          type related to this internet site or the materials contained on this
          website. additionally, nothing contained on this internet site shall
          be explained as advising you
        </p>{" "}
        <br />
        <br />
        <h3>Limitation of liability.</h3>
        <p>
          In no occasion shall FashionTV India LTD, nor any of its officials,
          administrators, and personnel shall be held responsible for something
          arising out of or in any manner connected along with your use of this
          website whether such liability is under contract. Organization name,
          consisting of its officials, administrators, and personnel shall no
          longer be held chargeable for any oblique, consequential or special
          liability coming out of or in any way related to your use of this
          website.
        </p>{" "}
        <br />
        <br />
        <h3>Indemnification.</h3>
        <p>
          You hereby compensate to the fullest extent FashionTV India LTD from
          and in opposition to any and/or all liabilities, prices, needs,
          reasons of movement, damages and expenses arising in any way
          associated with your breach of any of the provisions of these terms.
        </p>{" "}
        <br />
        <br />
        <h3>Severability.</h3>
        <p>
          If any provision of these terms is determined to be invalid underneath
          any relevant regulation, such provisions will be deleted without
          affecting the other provisions herein.
        </p>{" "}
        <br />
        <br />
        <h3>Variation of Terms.</h3>
        <p>
          By using this website you are supposed to review these Terms on a
          regular basis because FashionTV India LTD is allowed to revise these
          Terms at any time as it sees fit.
        </p>
        <br />
        <br />
        <h3>Assignment.</h3>
        <p>
          You are not permitted to assign, transfer, or subcontract any of your
          rights and/or obligations under these terms. But the company name is
          allowed to assign, transfer, and subcontract its rights and/or
          obligations without any notification.
        </p>{" "}
        <br />
        <br />
        <h3>Entire Agreement.</h3>
        <p>
          These terms represent the whole agreement between FashionTV India LTD
          and you in terms of your use of this internet site and supersede all
          prior agreements and understandings.
        </p>{" "}
        <br />
        <br />
        <h3>Governing Law and Jurisdiction.</h3>
        <p>
          These terms will be governed by the legal guidelines of The Indian
          Jurisdiction. You post to the non-exclusive jurisdiction of the state
          and federal courts placed in the country for the resolution of any
          disputes.
        </p>{" "}
        <br />
        <br />
        <h3>Contact Us.</h3>
        <p>
          If you have any queries about these Terms and Conditions, please
          contact us.<strong>info@fashiontvindia.in.</strong>
        </p>{" "}
        <br />
        <br />
        <h3>Press</h3>
        <p>
          We are adored by our purchasers and cherished by everyone. Our
          top-notch brand experience never fails to satisfy our consumers hence
          FTV is always in the spotlight of media coverage. We are recognised
          and honoured by various media in India.
        </p>
      </div>
    </>
  );
};

export default Terms;
