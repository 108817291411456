import React from "react";
import Parallax from "./Parallax";

const Form = () => {
  let data = [
    {
      state: "Andhra Pradesh",
      value: "Andhra Pradesh",
    },
    {
      state: "Arunachal Pradesh",
      value: "Arunachal Pradesh",
    },
    {
      state: "Assam",
      value: "Assam",
    },
    {
      state: "Bihar",
      value: "Bihar",
    },
    {
      state: "Chhattisgarh",
      value: "Chhattisgarh",
    },
    {
      state: "Goa",
      value: "Goa",
    },
    {
      state: "Gujarat",
      value: "Gujarat",
    },
    {
      state: "Haryana",
      value: "Haryana",
    },
    {
      state: "Himachal Pradesh",
      value: "Himachal Pradesh",
    },
    {
      state: "Jharkhand",
      value: "Jharkhand",
    },
    {
      state: "Karnataka",
      value: "Karnataka",
    },
    {
      state: "Kerala",
      value: "Kerala",
    },
    {
      state: "Madhya Pradesh",
      value: "Madhya Pradesh",
    },
    {
      state: "Maharashtra",
      value: "Maharashtra",
    },
    {
      state: "Manipur",
      value: "Manipur",
    },
    {
      state: "Meghalaya",
      value: "Meghalaya",
    },
    {
      state: "Mizoram",
      value: "Mizoram",
    },
    {
      state: "Nagaland",
      value: "Nagaland",
    },
    {
      state: "Odisha",
      value: "Odisha",
    },
    {
      state: "Punjab",
      value: "Punjab",
    },
    {
      state: "Rajasthan",
      value: "Rajasthan",
    },
    {
      state: "Sikkim",
      value: "Sikkim",
    },
    {
      state: "Tamil Nadu",
      value: "Tamil Nadu",
    },
    {
      state: "Telangana",
      value: "Telangana",
    },
    {
      state: "Tripura",
      value: "Tripura",
    },
    {
      state: "Uttar Pradesh",
      value: "Uttar Pradesh",
    },
    {
      state: "Uttarakhand",
      value: "Uttarakhand",
    },
    {
      state: "West Bengal",
      value: "West Bengal",
    },
  ];
  return (
    <>
      <Parallax />
      <div className="fluidAuto">
        <form className="container1 form" style={{ padding: "10px" }} action="Action.php">
          <div className="grid1">
            <div className="group">
              <label htmlFor="">
                <p>Full name</p>
              </label>
              <input type="text" />
            </div>
            <div className="group">
              <label htmlFor="">
                <p>Mobile number</p>
              </label>
              <input type="text" />
            </div>
            <div className="group">
              <label htmlFor="">
                <p>Email id</p>
              </label>
              <input type="email" />
            </div>
            <div className="group">
              <label htmlFor="">
                <p>Select state</p>
              </label>
              <select>
                <option selected="true" disabled></option>
                {data.map((item) => {
                  return <option value={item.value}>{item.state}</option>;
                })}
              </select>
            </div>
            <div className="group">
              <label htmlFor="">
                <p>City</p>
              </label>
              <input type="text" />
            </div>
            <div className="group">
              <label htmlFor="">
                <p>Message</p>
              </label>
              <input type="text" />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <button>submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
