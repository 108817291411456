import React, { useEffect, useState } from "react";
import Parallax from "./Parallax";
import RedBlue from "./RedBlue";
//
import intro from "./video/Desktop/michel adam/desktop.mp4";
import blend from "./video/Desktop/michel adam/blend.mp4";

const MichelAdam = () => {
  const [count, setCount] = useState(1);
  const [head, setHead] = useState("");
  const [body, setBody] = useState("");

  let increase = () => {
    if (count === 4) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };
  let decrease = () => {
    if (count === 1) {
      setCount(4);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    if (count === 1) {
      setHead("Background");
      setBody(
        "Michel Adam Lisowski was born on April 16, 1950, in Warsaw and is of Jewish descent. He moved to Vienna in 1958, along with his family. Later, he was granted a scholarship for Mathematics at Princeton University. He started a textile business in Thailand called Eden Group. In 1997, he started FashionTV, an international fashion and lifestyle broadcasting television channel in France. Currently, he is the Chairman of Fashion TV (FTV) and also runs the franchise love-f-cafe in various European cities, including Monaco and Milan."
      );
    }
    if (count === 2) {
      setHead("Journey");
      setBody(
        "In 1958 his journey began when he travelled from Poland to Vienna and decided to venture into a subject that was the closest to his heart which is Fashion. At first, he started his own textile business in Thailand under a company called the Eden Group. Later he moved to France where Michel Adam Lisowski started a channel that is dedicated to fashion, it broadcasted 24*7. This led to the birth of FashionTV, in 1997. Today, FashionTV is loved globally and is the world’s largest fashion media."
      );
    }
    if (count === 3) {
      setHead("future");
      setBody(
        "With FashionTV, Michel Adam Lisowski is committed to building partnerships that propel the partners towards success. Michel Adam Lisowski's entrepreneurial mindset makes him think beyond, and he is always interested in taking fashion to new heights. He is keenly aware of the potential and reach of FTV in India. The brand is  introducing various LUXURIOUS FTV businesses in the world’s second most populated country."
      );
    }
    if (count === 4) {
      setHead("message");
      setBody(
        "Hi, this is Michel Adam, founder and chairman of FashionTV. I invite you to be a part of FashionTV with FTV Energy Drink, and likewise, many other business opportunities presented just for the people of India. Looking forward to welcoming you on board."
      );
    }
  }, [count]);
  return (
    <>
      <Parallax />
      <section className="adam1 fluid">
        <video loop autoPlay muted>
          <source src={intro} type="video/mp4" />
        </video>
        <div className="content">
          <h1>michel adam</h1>
          <h2>founder and chairman</h2>
        </div>
      </section>

      <section className="adam2 fluid">
        <RedBlue />
        <div className="container1">
          <h1>introduction</h1>
          <p>
            Michel Adam Lisowski is the founder and chairman of FashionTV. He
            founded FashionTV to create a global fashion platform that focuses
            on fashion, luxury, and beauty. He is known as ‘FASHION MAESTRO’ for
            his remarkable contribution to the fashion, luxury, and lifestyle
            industry across the world. <br />
            He is the founder of multiple FashionTV destinations that are spread
            across the world’s leading cities like Tel Aviv, Cairo, Bangkok, Abu
            Dhabi, Kuwait, Shanghai, Prague, Vienna, etc.
          </p>
        </div>
      </section>

      <section className="adam3 fluid">
        <video loop autoPlay muted className=".blendVideo">
          <source src={blend} type="video/mp4" />
        </video>
      </section>

      <section className="adam4">
        <div className="container">
          <h1>{head}</h1>
          <p>{body}</p>
        </div>
        <button className="btn btn1" onClick={decrease}>
          <i className="fas fa-caret-left"></i>
        </button>
        <button className="btn btn2" onClick={increase}>
          <i className="fas fa-caret-right"></i>
        </button>
      </section>
    </>
  );
};

export default MichelAdam;
