import React, { useEffect, useState, useRef } from "react";
import Parallax from "./Parallax";
//
import intro from "./video/Desktop/fashiontv/fashion1.mp4";
import video from "./video/Desktop/home/home.mp4";

const Features = () => {
  const dot1 = useRef(null);
  const dot2 = useRef(null);
  const dot3 = useRef(null);
  const dot4 = useRef(null);

  //
  const [first] = useState(1);
  const [second] = useState(2);
  const [third] = useState(3);
  const [forth] = useState(4);
  //

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [number, setNumber] = useState("");

  const [count, setCount] = useState(1);
  let increase = () => {
    if (count === 4) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };
  let decrease = () => {
    if (count === 1) {
      setCount(4);
    } else {
      setCount(count - 1);
    }
  };

  let jump = (e) => {
    if (e === 1) {
      setCount(1);
    }
    if (e === 2) {
      setCount(2);
    }
    if (e === 3) {
      setCount(3);
    }
    if (e === 4) {
      setCount(4);
    }
  };

  useEffect(() => {
    if (count === 1) {
      setNumber("01");
      setTitle("Improves physical endurance");
      setBody(
        "In several research studies, energy liquids were discovered to improve physical endurance. Power drinks might also amplify alertness and improve response time."
      );
      dot1.current.classList.add("dotActive");
      dot2.current.classList.remove("dotActive");
      dot3.current.classList.remove("dotActive");
      dot4.current.classList.remove("dotActive");
    } else if (count === 2) {
      setNumber("02");
      setTitle("Perfect partner for sports activities");
      setBody(
        "FTV energy drinks are the best partner for the people engaged in sports activities like, athletics, socker, hockey etc. It enhances the endurance and can be a reason to improve your performance by boosting up your energy so that you can perform well and give your hundred percent. "
      );
      dot1.current.classList.remove("dotActive");
      dot2.current.classList.add("dotActive");
      dot3.current.classList.remove("dotActive");
      dot4.current.classList.remove("dotActive");
    } else if (count === 3) {
      setNumber("03");
      setTitle("International flavors");
      setBody(
        "FTV Energy Drinks brings you the best flavors from all over the world, that perfectly fit with your mood and choice."
      );
      dot1.current.classList.remove("dotActive");
      dot2.current.classList.remove("dotActive");
      dot3.current.classList.add("dotActive");
      dot4.current.classList.remove("dotActive");
    } else if (count === 4) {
      setNumber("04");
      setTitle("Environmental friendliness");
      setBody(
        "FTV is not only aware about the quality of energy drinks but also for the environment's safety. That is the reason why our products are sustainable and cruelty free. "
      );
      dot1.current.classList.remove("dotActive");
      dot2.current.classList.remove("dotActive");
      dot3.current.classList.remove("dotActive");
      dot4.current.classList.add("dotActive");
    }
  }, [count]);
  return (
    <>
      <Parallax />
      <section className="adam1 fluid">
        <video loop autoPlay muted>
          <source src={intro} type="video/mp4" />
        </video>
        <div className="content">
          <h1>ftv energy drinks</h1>
        </div>
      </section>
      {/*  */}
      <section
        className="feature2 fluidAuto"
        style={{ padding: "50px", textAlign: "center" }}
      >
        <h1>features</h1>
      </section>
      {/*  */}
      <section className="feature3 fluidAuto">
        <div className="container1">
          <div className="flexBox">
            <h3>{title}</h3>
            <h1>{number}</h1>
          </div>
          <p>{body}</p>
          <div className="dots">
            <div className="dot" ref={dot1} onClick={() => jump(first)}></div>
            <div className="dot" ref={dot2} onClick={() => jump(second)}></div>
            <div className="dot" ref={dot3} onClick={() => jump(third)}></div>
            <div className="dot" ref={dot4} onClick={() => jump(forth)}></div>
          </div>
        </div>
        <button className="btn1" onClick={decrease}>
          <i className="fas fa-caret-left"></i>
        </button>
        <button className="btn2" onClick={increase}>
          <i className="fas fa-caret-right"></i>
        </button>
      </section>
      {/*  */}
      <section
        className="feature4 fluidAuto"
        style={{ padding: "50px 0", textAlign: "center" }}
      >
        <h1 style={{ fontFamily: "PlayFair", textTransform: "capitalize" }}>
          flavors
        </h1>
      </section>
      {/*  */}
      <section className="feature5 fluid">
        <div className="container1">
          <video loop autoPlay muted style={{mixBlendMode : "screen"}}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
};

export default Features;
