import React from "react";
import { Link } from "react-router-dom";

const Enquire = () => {
  return (
    <Link to="/form" className="enquire">
      <button>Enquire Now</button>
    </Link>
  );
};

export default Enquire;
