import React, { useRef, createContext } from "react";
import "./Style.css";
import "./Responsive.css";
import logo from "../src/components/image/logo.png";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

//
import Index from "./components/Index";
import MichelAdam from "./components/MichelAdam";
import KashiffKhan from "./components/KashiffKhan";
import FashionTv from "./components/FashionTv";
import Channels from "./components/Channels";
import Concepts from "./components/Concepts";
import Features from "./components/Features";
import Franchise from "./components/Franchise";
import Footer from "./components/Footer";
import Hamburger from "./components/Hamburger";
import ScrollToTop from "./components/ScrollToTop";
import Careers from "./components/Careers";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Enquire from "./components/Enquire";
import Form from "./components/Form";

export const navbarContext = createContext();
export const barContext = createContext();

const App = () => {
  const navbar = useRef(null);

  let links = document.querySelectorAll("nav a");

  return (
    <>
      <navbarContext.Provider value={navbar}>
        <Hamburger link={links} />
        <nav ref={navbar}>
          <Link className="link" to="/">
            Home
          </Link>
          <Link className="link" to="/micheladam">
            Michel Adam
          </Link>
          <Link className="link" to="/kashiffkhan">
            Kashiff Khan
          </Link>
          <Link className="link" to="/fashiontv">
            FashionTV
          </Link>
          <div className="logo">
            <img src={logo} alt="img" />
          </div>
          <Link className="link" to="/channels">
            Channels
          </Link>
          <Link className="link" to="/concepts">
            FTV Energy Drink
          </Link>
          <Link className="link" to="/features">
            Features
          </Link>
          <Link className="link" to="/franchise">
            Franchise
          </Link>
          <a
            href="http://ftvenergydrinks.in/edForm/form.html"
            target="_blank"
            rel="noreferrer"
            className="enquire"
          >
            Enquire Now
          </a>
        </nav>

        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/micheladam" element={<MichelAdam />} />
          <Route path="/kashiffkhan" element={<KashiffKhan />} />
          <Route path="/fashiontv" element={<FashionTv />} />
          <Route path="/channels" element={<Channels />} />
          <Route path="/concepts" element={<Concepts />} />
          <Route path="/features" element={<Features />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsandcondition" element={<Terms />} />
          <Route path="/enquire" element={<Enquire />} />
          <Route path="/form" element={<Form />} />
        </Routes>
        <Footer />
        <ScrollToTop />
      </navbarContext.Provider>
    </>
  );
};

export default App;
