import React from "react";
import Parallax from "./Parallax";

const Careers = () => {
  return (
    <>
      <Parallax />
      <div className="fluid" style={{ padding: "100px 10px 50px" }}>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Careers</h1>
        <p style={{textAlign : "center"}}>
          Not a single revolution was birthed from a single individual. Believe
          it or not it takes an entire team to unite and put their abilities
          together to generate greatness. Together we work hard, we laugh a lot,
          we brainstorm and ideate, we fraternize and create, we conduct
          meetings and celebrate national days together while using gazillions
          of post it notes and reload print cartridges every second day while
          giving the best fist bumps in Mumbai. We at FashionTV The World's
          Largest Fashion and Lifestyle Media Brand are looking for talented and
          passionate people to work with us. If you think you have what it takes
          to be a fragment of our constellation, a fragment that is integral to
          our functionality then we invite you to come work with us.
          <br />
          Our latest job updates can be found here, so stay tuned and get ready
          to be a part of the leaders of the Fashion Community.
        </p>
      </div>
    </>
  );
};

export default Careers;
