import React, { useState, useEffect } from "react";
import Parallax from "./Parallax";
import RedBlue from "./RedBlue";
//

const KashiffKhan = () => {
  const [count, setCount] = useState(1);
  const [head, setHead] = useState("");
  const [body, setBody] = useState("");

  let increase = () => {
    if (count === 2) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };
  let decrease = () => {
    if (count === 1) {
      setCount(2);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    if (count === 1) {
      setHead("Background");
      setBody(
        "Kashiff Khan comes with an ocean of experience in OTT/broadcast and a large gamut of various business ventures. Bringing in new concepts to the business world, ranging from wellness, beauty, fitness, F&B, fashion retail, print, and channel broadcast syndication to communication, ITES, and many more in the domain of worldwide operation and its working exposure."
      );
    }
    if (count === 2) {
      setHead("Journey");
      setBody(
        "Before venturing on his exciting journey of entrepreneurship, Kashiff gained his ground-level experience working in an Indian MNC SFA, the makers of world renowned paper and board products Nightingale brand. He started off as a mere export sales executive at SFA and gradually rose to become the director on board of the same company. The journey that took off as a sales executive is now in a phase of serial entrepreneurship."
      );
    }
  }, [count]);
  return (
    <>
      <Parallax />
      <section className="adam1 kk1 fluid">
        {/* <img src={kk} alt="img" style={{ objectFit: "cover" }} /> */}
        <div className="content">
          <h1>kashiff khan</h1>
          <h2>Managing Director,FashionTV</h2>
        </div>
      </section>

      <section className="kk2 fluid">
        <RedBlue />
        <div className="container1">
          <h1>introduction</h1>
          <p>
            Kashiff Khan is an esteemed name in the world of Brand Business
            Development in India. Mr. Kashiff is also recognised as an author,
            writer, and start-up specialist for businesses. <br />
            He is a knowledgeable titan in OTT and broadcast while birthing new
            concepts for business ideas in the wellness, beauty,fitness,print
            and channel broadcast syndication and a master of communication.
          </p>
        </div>
      </section>

      <section className="adam4 fluid">
        <div className="container">
          <h1>{head}</h1>
          <p>{body}</p>
        </div>
        <button className="btn btn1" onClick={increase}>
          <i className="fas fa-caret-left"></i>
        </button>
        <button className="btn btn2" onClick={decrease}>
          <i className="fas fa-caret-right"></i>
        </button>
      </section>
    </>
  );
};

export default KashiffKhan;
