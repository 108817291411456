import React from "react";
import redBlue from "./image/21.png"


const RedBlue = () => {
  return (
    <div className="redBlue">
      <img src={redBlue} alt="img" />
    </div>
  );
};

export default RedBlue;
