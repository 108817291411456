import React, { useState, useEffect } from "react";
import Parallax from "./Parallax";
//
import concept1 from "./video/Desktop/concept/concept1.mp4";
import concept2 from "./video/Desktop/concept/concept2.mp4";

const Concepts = () => {
  const [count, setCount] = useState(1);
  const [head, setHead] = useState("");
  const [body, setBody] = useState("");
  const [number, setNumber] = useState("");

  let increase = () => {
    if (count === 3) {
      setCount(1);
    } else {
      setCount(count + 1);
    }
  };
  let decrease = () => {
    if (count === 1) {
      setCount(3);
    } else {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    if (count === 1) {
      setHead("vision");
      setNumber("01");
      setBody(
        "Michel Adam Lisowski was born on April 16, 1950, in Warsaw and is of Jewish descent. He moved to Vienna in 1958, along with his family. Later, he was granted a scholarship for Mathematics at Princeton University. He started a textile business in Thailand called Eden Group. In 1997, he started FashionTV, an international fashion and lifestyle broadcasting television channel in France. Currently, he is the Chairman of FashionTV (FTV) and also runs the franchise love-f-cafe in various European cities, including Monaco and Milan."
      );
    }
    if (count === 2) {
      setHead("mission");
      setNumber("02");
      setBody(
        "In 1958 his journey began when he travelled from Poland to Vienna and decided to venture into a subject that was the closest to his heart which is Fashion. At first, he started his own textile business in Thailand under a company called the Eden Group. Later he moved to France where Michel Adam Lisowski started a channel that is dedicated to fashion, it broadcasted 24*7. This led to the birth of FashionTV, in 1997. Today, FashionTV is loved globally and is the world’s largest fashion media."
      );
    }
    if (count === 3) {
      setHead("aim");
      setNumber("03");
      setBody(
        "With FashionTV, Michel Adam Lisowski is committed to building partnerships that propel the partners towards success. Michel Adam Lisowski's entrepreneurial mindset makes him think beyond, and he is always interested in taking fashion to new heights. He is keenly aware of the potential and reach of FTV in India. The brand is  introducing various LUXURIOUS FTV businesses in the world’s second most populated country."
      );
    }
  }, [count]);
  return (
    <>
      <Parallax />
      <section className="adam1 fluid">
        <video loop autoPlay muted>
          <source src={concept1} type="video/mp4" />
        </video>
        <div className="content">
          <h1>ftv energy drinks</h1>
        </div>
      </section>
      {/*  */}
      <section className="concept2 fluid">
        <div
          className="container1"
          style={{ height: "100%", textAlign: "center" }}
        >
          <h1>introduction</h1>
          <p>
            The global market of energy drinks is growing rapidly because the
            modern world needs something energetic for their highly hectic and
            fast paced life. So FashionTV has launched their brand new and
            refreshing FTV energy drinks just for you, with a sip that is going
            to be the best part of your long and hectic days by giving you
            enough energy to rock the day. FTV energy drinks are the boon for
            our youth, athletes, students and other professionals. FTV energy
            drinks contain caffeine, taurine, glucuronolactone, sucrose and
            glucose, B-group vitamins, and alpine spring water, which helps to
            boost up your energy.
          </p>
        </div>
      </section>
      {/*  */}
      <section className="concept3 fluid">
        <div className="container1">
          <video loop autoPlay muted>
            <source src={concept2} type="video/mp4" />
          </video>
        </div>
      </section>
      {/*  */}
      <section className="concept2 fluid">
        <div
          className="container1"
          style={{ height: "100%", textAlign: "center" }}
        >
          <h1>potential</h1>
          <p>
            India Energy Drink market is <b></b> projected to grow at a CAGR of
            9.22% during the forecast period <b></b>, 2020-2025. Maximum effects
            of FTV energy drinks on cognitive overall performance, which include
            improved interest and response speed. FTV energy drinks can increase
            mental and physical performance. Not only the best quality but FTV
            also offers you options of flavors in energy drinks that you can
            choose according to your mood. Moreover, FTV also pays attention
            that we have a responsibility to the natural environment, that's why
            our products are sustainable and friendly to the environment.
          </p>
        </div>
      </section>
      {/*  */}
      <section className="adam4 fluidAuto">
        <h1>philosophy</h1>
        <div className="container">
          <h1 style={{ marginBottom: "0" }}>{head}</h1>
          <div className="vma">
            <h1 style={{ marginBottom: "0" }}>{number}</h1>
            <div className="extra"></div>
            <p>{body}</p>
          </div>
        </div>
        <button className="btn btn1" onClick={decrease}>
          <i className="fas fa-caret-left"></i>
        </button>
        <button className="btn btn2" onClick={increase}>
          <i className="fas fa-caret-right"></i>
        </button>
      </section>
    </>
  );
};

export default Concepts;
