import React from "react";
import Parallax from "./Parallax";
//
import intro from "./video/Desktop/channel/intro.mp4";
import bridalfashion from "./video/channels/bridal fashion.mp4";
import fashionfilms from "./video/channels/fashion films.mp4";
import fashionweek from "./video/channels/fashion week.mp4";
import fmen from "./video/channels/fmen.mp4";
import ftvparis from "./video/channels/ftv paris.mp4";
import global from "./video/channels/global.mp4";
import hairandmakeup from "./video/channels/hair and makeup.mp4";
import loriginal from "./video/channels/loriginal.mp4";
import midnightsecrets from "./video/channels/midnight secrets.mp4";
import modelfitness from "./video/channels/model fitness.mp4";
import photoshoot from "./video/channels/photoshoot.mp4";
import topmodels from "./video/channels/top models.mp4";

const Channels = () => {
  return (
    <>
      <Parallax />
      <section className="adam1 fluid">
        <video loop autoPlay muted>
          <source src={intro} type="video/mp4" />
        </video>
        <div className="content">
          <h1>channels</h1>
          <h2>world's largest fashion media</h2>
        </div>
      </section>
      {/*  */}
      <section
        className="channel2 fluidAuto"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        {/*  */}
        <div className="container1 even one">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={midnightsecrets} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                Witness the world of sensuality and seduction on the Midnight
                Secrets channel, where beautiful women take you on the journey
                of sensuality and luxury.
              </li>
              <li>
                Watch the Midnight Secrets shows 24x7 available on our TV
                Network. You can also access the channel on-demand with our VOD
                service any time you want.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>A premium media channel made exclusively for men.</li>
              <li>
                With F-Men you can get access to the amazing shows related to
                men's Fashion, Beauty, Fitness, Style, Grooming, Clothing, Men's
                Fashion, and everything that revolves around the lives of men.
              </li>
            </ul>
          </div>
          <div className="flexChild two">
            <video loop autoPlay muted>
              <source src={fmen} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}
        <div className="container1 even three">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={hairandmakeup} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                FashionTV’s Hair and Makeup provides a plethora of intriguing
                content from the hairstyling and makeup industry.
              </li>
              <li>
                You can view the Hair and Makeup channel any time on your TV
                network or stream on-demand anytime you want.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>
                FashionTV L’Original gives you access to premium channel
                content, FTV fashion events like fashion weeks, calendar shoots,
                swimwear shoots, etc. across the world straight on your TV.{" "}
              </li>
              <li>
                You can also access the media channel on-demand from your
                smartphone.
              </li>
            </ul>
          </div>
          <div className="flexChild four">
            <video loop autoPlay muted>
              <source src={loriginal} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 even five">
          <div className="flexChild"></div>
          <div className="flexChild">
            <ul>
              <li>
                Our Photoshoots channel gives you backstage access to various
                FashionTV photoshoots.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>
                FashionTV brings you the most curated and FINEST collection of
                Fashion. Films of your favourite fashion designers and POPULAR
                fashion houses around the world.
              </li>
            </ul>
          </div>
          <div className="flexChild six">
            <video loop autoPlay muted>
              <source src={photoshoot} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 even seven">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={fashionfilms} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                With the Fashion Weeks media channel, you can catch the latest
                fashion TRENDS across the world from the top Fashion Weeks
                destinations like New York, Milan, London, and Paris.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>
                FashionTV gives you insights into the worldwide fashion industry
                at your fingertips. Get the latest updates, news, and alerts
                about the fashion world with our FashionTV Global channel.
              </li>
            </ul>
          </div>
          <div className="flexChild eight">
            <video loop autoPlay muted>
              <source src={fashionweek} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 even nine">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={global} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                FashionTV’s Model Fitness is where LUXURY meets fitness. It
                takes blood, sweat, and hard work to achieve your dream physique
                and we are your companions in your fitness journey.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>
                With FTV Top Model, you get to spectate the journey of some of
                the most popular and successful models across the world and how
                they played their roles in shaping the fashion industry.
              </li>
            </ul>
          </div>
          <div className="flexChild ten">
            <video loop autoPlay muted>
              <source src={modelfitness} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 even eleven">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={topmodels} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                You can catch the latest shows, fashion weeks, photoshoots,
                calendar shoots, after parties, behind the scenes of shows, and
                everything about fashion that goes around in Paris.
              </li>
              <li>
                With the rising popularity of the FashionTV+ media channel and
                the demand of the audience, FashionTV will launch some exclusive
                new media on the FashionTV+ platform. Media such as Men’s
                Secrets, Lingerie, 4K Channel, VR Channel, and FashionTV China
                will be soon added to the FashionTV+ platform.
              </li>
            </ul>
          </div>
        </div>
        {/*  */}{" "}
        <div className="container1 odd">
          <div className="flexChild">
            <ul>
              <li>
                FashionTV brings you a Bridal Fashion channel to give you
                insights into the world of bridal fashion.
              </li>
            </ul>
          </div>
          <div className="flexChild twelve">
            <video loop autoPlay muted>
              <source src={ftvparis} type="video/mp4" />
            </video>
          </div>
        </div>
        {/*  */}
        <div className="container1 even thirteen">
          <div className="flexChild">
            <video loop autoPlay muted>
              <source src={bridalfashion} type="video/mp4" />
            </video>
          </div>
          <div className="flexChild">
            <ul>
              <li>
                You can catch the latest shows, fashion weeks, photoshoots,
                calendar shoots, after parties, behind the scenes of shows, and
                everything about fashion that goes around in Paris.
              </li>
              <li>
                With the rising popularity of the FashionTV+ media channel and
                the demand of the audience, FashionTV will launch some exclusive
                new media on the FashionTV+ platform. Media such as Men’s
                Secrets, Lingerie, 4K Channel, VR Channel, and FashionTV China
                will be soon added to the FashionTV+ platform.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Channels;
