import React, { useRef } from "react";
import Parallax from "./Parallax";
import download from "./image/download.png";
//
import intro from "./video/Desktop/franchise/intro.mp4";
import Kit from "../salesKit.pdf";

const Franchise = () => {
  const downloadBtn = useRef(null);
  let hover = () => {
    downloadBtn.current.style.animation = "buttonHover 1s linear";
  };
  let removeHover = () => {
    downloadBtn.current.style.animation = "none";
  };
  return (
    <>
      <Parallax />
      <section className="franchise1 fluid">
        <video autoPlay muted loop>
          <source src={intro} type="video/mp4" />
        </video>
        <div className="title">
          <h1>franchise kit</h1>
          <div className="download">
            <h2>download</h2>
            <a
              href={Kit}
              target="_blank"
              download="FTV Energy drink Franchise kit"
              onMouseEnter={hover}
              onMouseLeave={removeHover}
            >
              <img src={download} alt="img" ref={downloadBtn} />
            </a>
          </div>
        </div>
      </section>

      <section className="franchise2 fluidAuto">
        <h2>franchise</h2>
        <h1>pre-requisites</h1>
      </section>

      <section className="franchise3 fluidAuto">
        <div className="container">
          <div className="part1">
            <div className="subPart1">
              <h2>AAA location</h2>
              <p>
                FashionTV ENERGY DRINKS will be sold in high usage locations, at
                game centres, sports stadiums, skate parks, practice grounds,
                unique office environments, colleges and at gyms and
                functionality centres.
              </p>
            </div>
            <div className="subPart2"></div>
          </div>
          <div className="part2">
            <div className="subPart1"></div>
            <div className="subPart2">
              <h2>strong financial backings</h2>
              <p>
                All franchisers that purchase this FTV ENERGY DRINK franchise
                must be able to show strong financial backings to ensure the
                success of the business.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="part3">
            <div className="subPart1">
              <h2>franchise industry attraction</h2>
              <p>
                All franchisees must have an attraction and liking towards the
                desired industry that is of energy drinks, along with a zeal to
                learn, explore and strive to succeed.
              </p>
            </div>
            <div className="subPart2"></div>
          </div>
          <div className="part4">
            <div className="subPart1"></div>
            <div className="subPart2">
              <h2>strong desire to be more</h2>
              <p>Get rich, famous and successful.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="franchise4 fluidAuto" style={{ padding: "50px 0" }}>
        <div className="container1">
          <h1>License - module</h1>
          <table>
            <thead>
              <tr>
                <th>
                  <h2>module</h2>
                </th>
                <th>
                  <h2>standard</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h3>License rate</h3>
                </td>
                <td>
                  <h3>37.5 lacs</h3>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{marginTop : "10px"}}>
            {" "}
            <strong>*MANIFACTURING COSTS: ON ACTUALS </strong>
          </p>
        </div>
      </section>
      <section className="franchise5 fluidAuto">
        <h1>brand support</h1>
        <p>
          FashionTV gives 360-degree support to all our franchise partners. We
          help in all aspects ranging from location, architecture, staff
          recruitment to PR launch plan, social media marketing, auditing, cross
          marketing and more. <br />
          Strategic planning which is very important for the success of any
          business, is worked on extensively by our team and franchise partners.
        </p>
      </section>
    </>
  );
};

export default Franchise;
