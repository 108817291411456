import React, { useContext, useRef, useState } from "react";
import { navbarContext } from "../App";
import extraLogo from "./image/logo.png";

const Hamburger = ({link}) => {
  const navbar = useContext(navbarContext);

  const extraLogoRef = useRef(null);
  const bar1 = useRef(null);
  const bar2 = useRef(null);
  const bar3 = useRef(null);
  const hamburgerMenu = useRef(null)
  const [status, setStatus] = useState(false)


  let toggleNavbar = () => {
    navbar.current.classList.toggle("navbarActive");
    extraLogoRef.current.classList.toggle("extraLogoHide");
    bar1.current.classList.toggle("toggleBar");
    bar2.current.classList.toggle("toggleBar");
    bar3.current.classList.toggle("toggleBar");

    hamburgerMenu.current.classList.toggle("rotate")

    setStatus(!status)

    link.forEach((item) => {
      if (status === true) {
          item.style.animation = "effect 0.3s linear"
          item.style.transition = "all 0.3s linear"
          item.style.animationFillMode = "forwards"
      } else if (status === false) {
          item.style.animation = "none"
          item.style.transition = "none"
          item.style.animationFillMode = "none"
      }
  })
  };
  return (
    <>
      <div className="extraLogo" ref={extraLogoRef}>
        <img src={extraLogo} alt="img" />
      </div>
      <div className="hamburger" onClick={toggleNavbar} ref={hamburgerMenu}>
        <div className="bar bar1" ref={bar1}></div>
        <div className="bar bar2" ref={bar2}></div>
        <div className="bar bar3" ref={bar3}></div>
      </div>
    </>
  );
};

export default Hamburger;
