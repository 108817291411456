import React from "react";
import Parallax from "./Parallax";

const Privacy = () => {
  return (
    <>
      <Parallax />
      <div className="fluidAuto" style={{ padding: "100px 10px 50px" }}>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          Privacy Policy
        </h1>
        <p>
          FashionTV India operates FTV Energy drinks.in. This page informs you of our
          policies regarding the collection, use, and disclosure of personal
          information we receive from users of the site. We use your Personal
          Information only for internal FTV Energy drinks internal processes and
          improving your experience on the site. By using the site, you agree to
          the collection and use of information in accordance with this policy.
          <br />
          This policy applies to all visitors to FashionTV's websites. <br />
          2. Information covered by this Policy <br />
          This policy applies to information collected and processed by
          FashionTV consisting of the following:
          <br />
          2.1 Personal information is information related to a visitor or a
          combination of pieces of information that could reasonably allow him
          to be identified. Personal information may consist of Full Name, Date
          of Birth, Age, Current City, Birth City, Mobile Number, Email ID,
          Social Media Handles like Facebook, Instagram, and Twitter, Marital
          Status, Passport Details, and photo images. While information such as
          date of birth in isolation may not be enough to identify the visitor
          uniquely, a combination of full name and date of birth may be
          sufficient to do so. The Personal Information will be used for the
          internal processes of the FashionTV website, such as the following:{" "}
          <br />
        </p>
        <ul>
          <li>Screening and Selection process of the participants.</li>
          <li>
            To recognize a unique anonymous user and enhance its user
            experience.
          </li>
          <li>
            To communicate and notify you about FashionTV's latest updates,
            offers, events, etc.
          </li>
        </ul>
        <p>
          2.2 Sensitive personal data or information ("SPDI") is such personal
          information that is collected, received, stored, transmitted, or
          processed by FashionTV, consisting of: <br />
        </p>
        <br />
        <ul>
          <li>Password;</li>
          <li>
            Financial information such as bank account or credit card or debit
            card or other payment instrument details;
          </li>
          <li>Physical, physiological, and mental health condition;</li>
          <li>Sexual orientation;</li>
          <li>Medical records and history;</li>
          <li>Biometric information;</li>
          <li>
            Any detail relating to the above personal information categories as
            provided to FashionTV for providing service; and
          </li>
          <li>
            Any of the information received under the above personal information
            categories by FashionTV for processing, stored, or processed under
            lawful contract or otherwise.
          </li>
        </ul>
        <p>
          Please note that any information that is freely available or
          accessible in the public domain or furnished under the Right to
          Information Act, 2005 or any other law for the time being in force
          shall not be regarded as sensitive personal information.
        </p>
        <p>
          2.3 Log Data Like many site operators, we collect information that
          your browser sends whenever you visit our site ("Log Data").
        </p>
        <p>
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser type, browser version, and the pages
          of our site that you visit, the time and date of your visit, the time
          spent on those pages and other statistics.
        </p>
        <p>
          In addition, we may use third-party services such as Google Analytics
          that collect, monitor, and analyze the information and help us with
          the insights to ensure our users get the best experience.
        </p>
        <br />
        <br />
        <h3>Purpose</h3>
        <p>
          FashionTV shall collect and use the information for legitimate
          business purposes in order:
        </p>
        <p>
          That a visitor may download product information, order products, and
          take advantage of certain other features of FashionTV's website;
        </p>
        <ul>
          <li>
            To provide information or interactive services through this website,
            to the visitor's email address or, where the visitor wishes it to be
            sent by post, to the visitor's postal address;
          </li>
          <li>
            To seek the visitor's feedback or to contact the visitor in relation
            to the services offered on the FashionTV's website;
          </li>
          <li>To process orders or applications submitted by the visitor;</li>
          <li>
            To administer or otherwise carry out FashionTV's obligations in
            relation to any agreement that the visitor may have with FashionTV;
          </li>
          <li>
            To anticipate and resolve problems with any goods or services
            supplied to the visitor;
          </li>
          <li>
            To create products or services that may meet the visitor's needs;
          </li>
          <li>
            To process and respond to requests, improve FashionTV operations,
            and communicate with visitor/s about FashionTV products, services,
            and businesses; and
          </li>
          <li>To allow the visitor to subscribe to FashionTV news alerts.</li>
        </ul>
        <br />
        <br />
        <h3>Collection of Information.</h3>
        <p>
          Only the minimum information required to meet the purposes mentioned
          in this policy shall be collected from the visitor/s. Neither
          FashionTV nor its representatives shall be responsible for the
          authenticity of such information provided by the visitor/s.
        </p>
        <p>
          As a normal business practice, FashionTV may collect information in
          order to enable secure online authentication, interaction, and
          transaction with natural persons. This may include the installation of
          cookies and the collection of other session data.
        </p>
        <br />
        <br />
        <h3>Cookies Policy</h3>
        <p>
          Cookies are files with a small amount of data, which may include a
          unique anonymous identifier. Cookies are sent to your browser from a
          website and stored on your computer's hard drive. Like many sites, we
          use "cookies" to collect information.
          <br />
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our site. We use cookies
          for the following purposes:
        </p>
        <dl>
          <dt>Authentication</dt>
          <dd>
            Cookies help us determine if a user is logged in and then deliver
            the right experience and features to that unique user.
          </dd>
          <dt>Security</dt>
          <dd>
            Cookies help us impose security measures on our website. They also
            help detect unusual and suspicious activities.
          </dd>
          <dt>Advertising</dt>
          <dd>
            Cookies deliver a better advertising experience for both users and
            us. Cookies help connect us to users who are most interested in our
            services and products based on the user's browsing history.
          </dd>
          <dt>Performance</dt>
          <dd>
            Cookies help us to learn how services work for different people and
            how to route traffic between servers.
          </dd>
          <dt>Analytics and Research</dt>
          <dd>
            We use cookies to learn which of our services are most used. This
            helps us to determine what to improve, what to remove, and what to
            leave the same, hence providing the users a better experience.
          </dd>
        </dl>
        <br />
        <br />
        <h3>Access, Correction of Information, and withdrawal of consent.</h3>
        <p>
          Any modifications/corrections required to the information can be
          carried out on the website. In the event the visitor is unable to do
          so due to lack of functionality on the FashionTV website and/or the
          visitor wants to withdraw his/her consent to provide SPDI, he/she may
          contact the Grievance Officer, the details whereof are provided in
          clause 10 of this policy.
        </p>
        <br />
        <br />
        <h3>Retention, Processing, and storage of information.</h3>
        <p>
          6.1 FashionTV shall retain information for only as long as necessary
          to meet legal or regulatory requirements or for legitimate business
          purposes as mentioned in this policy.
        </p>
        <p>
          6.2 FashionTV has implemented required security practices and
          standards in line with the global standards and has a comprehensive
          documented information security program and policy in place, which
          contains managerial, technical, operational, and physical security
          control measures that commensurate with the information assets being
          protected with FashionTV's nature of business. It is being reviewed
          periodically to keep pace with business, technology, and regulatory
          changes.
        </p>{" "}
        <br />
        <br />
        <h3>Disclosure of Information.</h3>
        <p>
          7.1 FashionTV shall not use or disclose information for purposes other
          than as mentioned in this policy, except with the consent of visitors
          providing such information or as required by law.
        </p>
        <p>
          However, FashionTV may be legally required to disclose the information
          in the following cases:
        </p>
        <ul>
          <li>
            Where the disclosure is necessary for compliance of a legal
            obligation;
          </li>
          <li>
            Where mandated under the law by government agencies to disclose such
            information.
          </li>
        </ul>
        <p>
          7.2 Where necessary, FashionTV may disclose information to business
          partners or third parties during the normal course of business for the
          purposes mentioned in this policy. In such cases, FashionTV will only
          share information related data when we are assured that:
        </p>
        <ul>
          <li>
            The information is processed legitimately and appropriately by
            FashionTV or a third party in line with the established consent or
            in line with legal requirements.
          </li>
          <li>
            FashionTV or third parties have adopted a reasonable and equivalent
            level of security practices and procedures to ensure the security of
            the information shared.
          </li>
        </ul>{" "}
        <br />
        <br />
        <h3>Security</h3>
        <p>
          The security of your Personal Information is important to us but
          remember that no method of transmission over the Internet, or method
          of electronic storage, is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <br />
        <br />
        <h3>Changes to This Privacy Policy.</h3>
        <p>
          This Privacy Policy is effective as of November 2019 and will remain
          in effect except concerning any changes in its provisions in the
          future, which will be in effect immediately after being posted on this
          page. We reserve the right to update or change our Privacy Policy at
          any time, and you should check this FashionTV's Privacy Policy page
          periodically. Your continued use of the service after we post any
          modifications to the Privacy Policy on this page will constitute your
          acknowledgment of the modifications and your consent to abide and be
          bound by the modified Privacy Policy. If we make any material changes
          to this Privacy Policy, we will notify you either through the email
          address you have provided us or by placing a prominent notice on our
          website.
        </p>
        <br />
        <br />
        <h3>Contact Us.</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at the following details:
          <strong>info@fashiontvindia.in</strong>.
        </p>
      </div>
    </>
  );
};

export default Privacy;
