import React, { useRef } from "react";
import logo from "../components/image/logo.png";
// 
import { Link } from "react-router-dom";

const Footer = () => {
  const myInput = useRef(null);

  let addBorder = () => {
    myInput.current.style.borderBottom = "2px solid #fff";
  };
  let removeBorder = () => {
    myInput.current.style.borderBottom = "2px solid var(--grey)";
  };

  return (
    <footer className="fluidAuto">
      <div className="part1">
        <ul className="sub1">
          <li>
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/ftv.energydrink.in/" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-youtube"></i>
            </a>
          </li>
        </ul>
        <ul className="sub2">
          <ul>
            <Link to="/careers">careers</Link>
          </ul>
          <ul>
            <Link to="/privacy">privacy policy</Link>
          </ul>
          <ul>
            <Link to="/termsandcondition">terms and condition</Link>
          </ul>
        </ul>
        <div className="sub3">
          <p>Copyright 2021 FTV Energy drinks All Rights Reserved</p>
        </div>
      </div>
      <div className="part2">
        <h1>Subscribe to our Newsletter</h1>

        <form>
          <input
            type="text"
            placeholder="Email address"
            ref={myInput}
            onFocus={addBorder}
            onBlur={removeBorder}
          />
          <button>submit</button>
        </form>
      </div>
      <div className="part3">
        <img src={logo} alt="img" />
      </div>
    </footer>
  );
};

export default Footer;
