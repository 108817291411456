import React from "react";

const ScrollToTop = () => {
  return (
      <a href="#" className="scroll">
        <i className="fas fa-arrow-circle-up"></i>
      </a>
  );
};

export default ScrollToTop;
