import React, { useEffect, useState, useRef } from "react";
//
import acc1 from "./video/Desktop/accordion/acc1.mp4";
import acc2 from "./video/Desktop/accordion/acc2.mp4";
import acc3 from "./video/Desktop/accordion/acc3.mp4";

const Carousel = () => {
  const [count, setCount] = useState(1);
  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);

  let pushBtn = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    if (count === 4) {
      setCount(1);
    }
  }, [count]);

  useEffect(() => {
    if (count === 1) {
      box1.current.style.top = "50%";
      box1.current.style.left = "50%";
      box1.current.style.right = "";
      box1.current.style.transform = "translate(-100%, -50%) scale(0.8)";
      box1.current.style.zIndex = "4";
      //
      box2.current.style.top = "50%";
      box2.current.style.left = "50%";
      box2.current.style.right = "";
      box2.current.style.transform = "translate(-50%, -50%)";
      box2.current.style.zIndex = "5";
      //
      box3.current.style.top = "50%";
      box3.current.style.right = "50%";
      box3.current.style.left = "";
      box3.current.style.transform = "translate(100%, -50%) scale(0.8)";
      box3.current.style.zIndex = "4";
    }
    if (count === 2) {
      box3.current.style.top = "50%";
      box3.current.style.left = "50%";
      box3.current.style.right = "";
      box3.current.style.transform = "translate(-100%, -50%) scale(0.8)";
      box3.current.style.zIndex = "4";
      //
      box1.current.style.top = "50%";
      box1.current.style.left = "50%";
      box1.current.style.right = "";
      box1.current.style.transform = "translate(-50%, -50%)";
      box1.current.style.zIndex = "5";
      //
      box2.current.style.top = "50%";
      box2.current.style.right = "50%";
      box2.current.style.left = "";
      box2.current.style.transform = "translate(100%, -50%) scale(0.8)";
      box2.current.style.zIndex = "4";
    }
    if (count === 3) {
      box2.current.style.top = "50%";
      box2.current.style.left = "50%";
      box2.current.style.right = "";
      box2.current.style.transform = "translate(-100%, -50%) scale(0.8)";
      box2.current.style.zIndex = "4";
      //
      box3.current.style.top = "50%";
      box3.current.style.left = "50%";
      box3.current.style.right = "";
      box3.current.style.transform = "translate(-50%, -50%)";
      box3.current.style.zIndex = "5";
      //
      box1.current.style.top = "50%";
      box1.current.style.right = "50%";
      box1.current.style.left = "";
      box1.current.style.transform = "translate(100%, -50%) scale(0.8)";
      box1.current.style.zIndex = "4";
    }
  }, [count]);

  return (
    <div className="carousel fluid">
      <div className="box1 box" ref={box1}>
        <video loop autoPlay muted>
          <source src={acc1} type="video/mp4" />
        </video>
      </div>
      <div className="box2 box" ref={box2}>
        <video loop autoPlay muted>
          <source src={acc2} type="video/mp4" />
        </video>
      </div>
      <div className="box3 box" ref={box3}>
        <video loop autoPlay muted>
          <source src={acc3} type="video/mp4" />
        </video>
      </div>

      <button className="btnLeft" onClick={pushBtn}>
        <i className="fas fa-caret-left"></i>
      </button>
      <button className="btnRight" onClick={pushBtn}>
        <i className="fas fa-caret-right"></i>
      </button>
    </div>
  );
};

export default Carousel;
